import React from "react";
import { css } from "@emotion/react";

const Title = (props) => {
  return (
    <span
      css={TitleStyle}
      style={{
        fontSize: props.size,
        color: props.color,
        textTransform: props.case,
      }}
    >
      {props.title}
    </span>
  );
};

export default Title;

const TitleStyle = css`
  position: relative;
  top: 0px;
  margin: 10px 0px;
  text-align: center;
  display: block;
  letter-spacing: 0;
  @media (max-width: 600px) {
    font-family: roboto-bold, Helvetica, Arial, sans-serif;
    font-size: 30px !important;
    letter-spacing: 0.025rem;
    font-weight: 450;
  }
`;
