import React, { useState, useEffect } from "react";
import { StaticImage } from "gatsby-plugin-image";

const Logo = () => {
  const [State, setState] = useState(0);

  useEffect(() => {
    setState(window.innerWidth);
  }, [State]);

  return (
    <>
      {State > 600 ? (
        <StaticImage
          src="../images/icon.png"
          alt="Adarsh Group"
          placeholder="blurred"
          layout="fixed"
          height={60}
        />
      ) : (
        <StaticImage
          src="../images/icon.png"
          alt="Adarsh Group"
          placeholder="blurred"
          layout="fixed"
          height={50}
        />
      )}
    </>
  );
};

export default Logo;
