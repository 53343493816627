import React from "react";
import { css } from "@emotion/react";

const Container = (props) => {
  return <div css={First}>{props.children}</div>;
};

export default Container;

const First = css`
  padding: 1.5vw 2vw 1.5vw 2vw;
  max-width: 99.9vw;
  margin: 1vw 1vw;
  position: relative;
  overflow: hidden;
  background-color: #fff;
  color: #000;
  letter-spacing: 0.03vw;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
  @meida(max-width:600px){
    letter-spacing: 0.03vw;
  }
`;
